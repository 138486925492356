import { Event, EventTypes, SearchedProductsResultsCountEvent } from "../../common/models/events/event.model";
import { EventsService } from "../../common/services/events.service";
import { Space } from "../../modules/SpacePicker/models/space.model";
import { ProductModel } from "../../modules/Visualizer/models/product.model";
import { FILTER_PRODUCTS, FILTER_PRODUCTS_BY_NAME, SET_PRODUCTS, SET_DISPLAYED_PRODUCTS, SET_SELECTED_PRODUCT, SET_SELECTED_PRODUCT_RESULT, SET_SELECTED_PRODUCT_RUG_RESULT } from "./productActionTypes";
import * as _ from "lodash"

export interface ProductsState {
    products: ProductModel[],
    displayedProducts: ProductModel[],
    selectedProduct: ProductModel,
    selectedProductResult: any,
    selectedProductRugResult: any
}

const initialState: ProductsState = {
    products: [],
    displayedProducts: [],
    selectedProduct: undefined,
    selectedProductResult: undefined,
    selectedProductRugResult: undefined
};

const productReducer = (state = initialState, action): ProductsState => {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                displayedProducts: action.payload
            }
        case SET_DISPLAYED_PRODUCTS:
            return {
                ...state,
                displayedProducts: action.payload
            }
        case FILTER_PRODUCTS:
            const filters: any = action.payload
            let displayedProducts: ProductModel[] = []
            if(filters)
                state.products.filter(product => {
                    Object.keys(filters).forEach(criteria => {
                        if(_.find(filters[criteria], (value) => _.includes(product.specifics[criteria], value))){
                            if(!displayedProducts.find(p => _.isEqual(p,product)))
                            displayedProducts.push(product)
                        }
                    })
                })
            return {
                ...state,
                displayedProducts: filters ? displayedProducts : state.products
            }
        case FILTER_PRODUCTS_BY_NAME:
            const text = action.payload
            const result = state.products.filter(product => product.name.toLowerCase().includes(text.toLowerCase()))
            EventsService.push(new Event(EventTypes.SearchedProductsResultsCountEvent, new SearchedProductsResultsCountEvent(text, result.length)))
            return {
                ...state,
                displayedProducts: result
            }
        case SET_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: action.payload
            }
        case SET_SELECTED_PRODUCT_RESULT:
            return {
                ...state,
                selectedProductResult: action.payload
        }
        case SET_SELECTED_PRODUCT_RUG_RESULT:
            return {
                ...state,
                selectedProductRugResult: action.payload
            }
        default:
            return state
    }
};

export default productReducer;