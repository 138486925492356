import { Box, Divider, Grid, IconButton, InputAdornment, OutlinedInput, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import { CloseOutlined, FilterAltOutlined, FilterList, GridViewOutlined, ListOutlined } from "@mui/icons-material";
import { hideCatalog, toggleCatalogShownAsList } from "../../../../../redux/general/generalActions";
import { FullState } from "../../../../../redux/rootReducer";

import floorIcon from "../../../../../assets/visualizer/floor.png"
import wallsIcon from "../../../../../assets/visualizer/walls.png"
import rugIcon from "../../../../../assets/visualizer/rug.png"
import { getFaIconsStyles } from "../../../../../common/styles/styles";
import { selectFloor, selectRug, selectWalls, setSelectedWalls } from "../../../../../redux/surfaces/surfacesActions";
import { useTranslation } from "react-i18next";
import theme from "@material-tailwind/react/theme";
import { SettingsService } from "../../../../../common/services/settings.service";
import { EventsService } from "../../../../../common/services/events.service";
import { Event, EventTypes, SurfaceTypeChangedEvent } from "../../../../../common/models/events/event.model";
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer";

export const WebSearchBar = (props) => {
    const {isCatalogShownAsList} = useSelector((state: FullState) => state.generals)
    const {isFloorSelected, isWallsSelected, isRugSelected} = useSelector((state: FullState) => state.surfaces)
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {products} = useSelector((state: FullState) => state.productsCatalog)

    const vendorHasRugsProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "interactive_floor")))
    const vendorHasFloorProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "floor")))
    const vendorHasWallsProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "walls")))

    return (
        <Box sx={{padding: 1, background: "white", borderRadius: 4 }}>
            <Grid container spacing={1} marginBottom={2}>
                {SettingsService.vendorHasFloor() && vendorHasFloorProducts() && <Grid item xs>
                    <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"row"} flex={1} 
                        onClick={() => {
                            if(!isFloorSelected){
                                dispatch(selectFloor())
                                EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), "floor")))
                            }
                        }}
                        sx={{   backgroundColor: isFloorSelected ? "#CBD4E7": "#CBD4E750", 
                            height: "45px", 
                            borderRadius: "8px",
                            border: "1px solid #CBD4E7",
                            cursor: "pointer",
                            transition: "background-color 0.6s", // Animation duration
                            "&:hover": {
                                backgroundColor: "#CBD4E7", // Adjusted color on hover
                            },
                            }}>
                        <img src={floorIcon} width={"20px"} height={"20px"} />
                        {<Typography variant="caption" fontWeight={"bold"} marginBlock={1} marginInline={1}>{t('VISUALIZER.BUTTONS.FLOOR')}</Typography>}
                    </Box>
                </Grid>}
                {SettingsService.vendorHasWalls() && vendorHasWallsProducts() && <Grid item xs>
                    <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"row"} flex={1} id="WallsSelector"
                        onClick={() => {
                            if(!isWallsSelected && selectedSpace.surfaceMarkers.walls){
                                dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls?.map(e => e.id)))
                                EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), "walls")))
                            }
                        }}
                        sx={{   backgroundColor: isWallsSelected ? "#CBD4E7": "#CBD4E750", 
                                height: "45px", 
                                borderRadius: "8px", 
                                border: "1px solid #CBD4E7",
                                cursor: "pointer",
                                transition: "background-color 0.6s", // Animation duration
                                "&:hover": {
                                    backgroundColor: "#CBD4E7", // Adjusted color on hover
                                },
                            }}>
                        <img src={wallsIcon} width={"20px"} height={"20px"} />
                        {<Typography variant="caption" fontWeight={"bold"} marginBlock={1} marginInline={1}>{t('VISUALIZER.BUTTONS.WALLS')}</Typography>}
                    </Box>
                </Grid>}
                {SettingsService.vendorHasRugs() && vendorHasRugsProducts() && <Grid item xs>
                    <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection={"row"} flex={1}
                        onClick={() => {
                            if(!isRugSelected){
                                dispatch(selectRug())
                                EventsService.push(new Event(EventTypes.SurfaceTypeChangedEvent, new SurfaceTypeChangedEvent(getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), "rug")))
                            }
                        }}
                        sx={{   backgroundColor: isRugSelected ? "#CBD4E7": "#CBD4E750", 
                                height: "45px", 
                                borderRadius: "8px", 
                                border: "1px solid #CBD4E7",
                                cursor: "pointer",
                                transition: "background-color 0.6s", // Animation duration
                                "&:hover": {
                                    backgroundColor: "#CBD4E7", // Adjusted color on hover
                                },
                                }}>
                        <img src={rugIcon} width={"20px"} height={"20px"} />
                        {<Typography variant="caption" fontWeight={"bold"} marginBlock={1} marginInline={1}>{t('VISUALIZER.BUTTONS.RUG')}</Typography>}
                    </Box>
                </Grid>}
            </Grid>
            <Divider />

            <Box sx={{display: "flex" }} marginBlock={2}>
                <Box sx={{display: "flex", flex: 0.5, alignItems: "center", backgroundColor: 'white', borderRadius: 2, border: "1px solid #D7DBE6", 
                            padding: 1, cursor: "pointer"}} justifyContent={"center"} onClick={props.handleOpenSearchBar}>
                        {/* <SearchIcon fontSize='medium' sx={{ color: '#7B8297'}}  /> */}
                        <i className="fad fa-magnifying-glass fa-lg" style={getFaIconsStyles()} ></i>
                </Box>
                <Box sx={{display: "flex", flex: 2, alignItems: "center", backgroundColor: 'white', borderRadius: 2, border: "1px solid #D7DBE6", 
                            padding: 1, cursor: "pointer"}} onClick={props.handleOpenDialog} justifyContent={"center"} marginInline={1}>
                        {/* <FilterList fontSize='medium' sx={{ color: '#7B8297', marginRight: 1}}  /> */}
                        <i className="fad fa-filter-list fa-lg" style={getFaIconsStyles()} ></i>
                        <Typography variant="caption" fontWeight={"bold"} marginTop={1} color="#7B8297" margin={0} marginLeft={1}>{t('VISUALIZER.BUTTONS.FILTERS')}</Typography>
                </Box>
                <Box sx={{display: "flex", flex: 1, alignItems: "center", backgroundColor: 'white', borderRadius: 2, border: "1px solid #D7DBE6", 
                            padding: 0.5}} justifyContent={"center"} >
                    {/* <ListOutlined fontSize='small' 
                        sx={{ cursor: 'pointer',
                            backgroundColor: isCatalogShownAsList ? '#7B8297': 'white', color: isCatalogShownAsList ? "white": '#7B8297', 
                            borderRadius: 1, padding: 0.5, paddingRight: 2, paddingLeft: 2}} onClick={() => dispatch(toggleCatalogShownAsList(true))}/>
                    <GridViewOutlined fontSize='small'
                        sx={{ cursor: 'pointer',
                            backgroundColor: !isCatalogShownAsList ? '#7B8297': 'white', color: !isCatalogShownAsList ? "white": '#7B8297', 
                            borderRadius: 1, padding: 0.5, paddingRight: 2, paddingLeft: 2}} onClick={() => dispatch(toggleCatalogShownAsList(false))}/> */}
                    <i className="fad fa-list fa-lg" style={{...getFaIconsStyles(), cursor: 'pointer', flex: 1, textAlign: "center",
                            backgroundColor: isCatalogShownAsList ? 'whitesmoke': 'white', 
                            borderRadius: 8, padding: 8, paddingRight: 10, paddingLeft: 10}} onClick={() => dispatch(toggleCatalogShownAsList(true))}></i>
                    <i className="fad fa-grip-vertical fa-lg" style={{...getFaIconsStyles(), cursor: 'pointer', flex: 1, textAlign: "center",
                            backgroundColor: !isCatalogShownAsList ? 'whitesmoke': 'white', 
                            borderRadius: 8, padding: 8, paddingRight: 10, paddingLeft: 10}} onClick={() => dispatch(toggleCatalogShownAsList(false))}></i>
                </Box>
            </Box>

            {props.isSearchBarOpen && <Box className="w-75" >
                    <OutlinedInput
                        size="small"  fullWidth
                        value={props.searchInputText} onChange={(e) => props.handleSearchProducts(e.target.value)} onKeyDown={(e) => props.handleKeyDown(e.key)} 
                        type='text'
                        sx={{marginTop: 1}}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton edge="end">
                            <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </Box>
            }

        </Box>
    );
}