
import { useEffect, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import {BrowserView, MobileView} from 'react-device-detect';
import { ARModelViewer } from "../modules/ARVisualizer/components/ARModelViewer";

export function ARVisualizer() {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true);
        }, 300)
    }, [])

    return (
        <div style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease' }}>
            <BrowserView>
                <QRCode value={window.location.href} />
            </BrowserView>
            <MobileView>
                <ARModelViewer />
            </MobileView>
        </div>
    );
}