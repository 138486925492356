import Config from "../models/config.model"
import { SettingsService } from "../services/settings.service"

export const getFaIconsStyles = () => {
    return {
        lineHeight: 1,
        "--fa-primary-color": SettingsService.settings?.primaryColor ?? "#2E41D3",
        "--fa-secondary-color": SettingsService.settings?.primaryColor ?? "#14a6ff",
        "--fa-primary-opacity": 1,
        "--fa-secondary-opacity": 0.8
    } 
}

export const notBookmarkedIconStyles = {
    lineHeight: 1,
    "--fa-primary-color": "white",
    "--fa-secondary-color": "black",
    "--fa-primary-opacity": 1,
    "--fa-secondary-opacity": 0.3,
} 

export const bookmarkedIconStyles = {
    lineHeight: 1,
    "--fa-primary-color": "#FA5961",
    "--fa-secondary-color": "#FA5961",
    "--fa-primary-opacity": 1,
    "--fa-secondary-opacity": 0.2,
} 
