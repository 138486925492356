import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { getFaIconsStyles } from "../../../../../common/styles/styles";

export const ScrollToTop = ({containerRef}) => {
    const [isScrolled, setIsScrolled] = useState(false);


    useEffect(() => {
        const onScroll = () => {
            if (containerRef.current.scrollTop > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        containerRef.current?.addEventListener('scroll', onScroll);
        return () => {
            containerRef.current?.removeEventListener('scroll', onScroll);
        };
    }, []);

    const scrollToTop = () => {
        containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return <Button 
        fullWidth
        onClick={scrollToTop} variant="text" color="primary" 
        sx={{ 
            position: 'absolute', 
            bottom: 0, 
            left: 0, 
            backgroundColor: "white",
            height: "40px",
            "&:hover": {
                backgroundColor: "white",
            },
            textTransform: "none",
            transition: "opacity 0.5s",
            opacity: isScrolled ? 1 : 0, // Adjust opacity based on scroll state
            pointerEvents: isScrolled ? "auto" : "none", // Enable/disable pointer events based on scroll state
        }}>
        Scroll to Top
        <i className="fa-duotone fa-arrow-up" style={{...getFaIconsStyles(), marginLeft: "5px"}}></i>
    </Button>
}