import {Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SideBar from "../modules/Visualizer/layout/SideBar";
import {Header} from "../modules/Visualizer/components/Header/Header";
import { FullState } from "../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { SpacePickerService } from "../modules/SpacePicker/services/space-picker.service";
import { setSelectedSpace } from "../redux/space/spaceActions";
import { SpaceCatalogService } from "../modules/Visualizer/services/space-catalog.service";
import { setSelectedProduct, setSelectedProductResult } from "../redux/product/productActions";
import SpaceResult from "../modules/Visualizer/layout/SpacePlayground";
import RightSideBar from "../modules/Visualizer/layout/RightSideBar";
import SpacePlayground from "../modules/Visualizer/layout/SpacePlayground";
import useWindowDimensions from "../common/hooks/GetWindowDimensions";
import { selectFloor, selectRug, selectWalls, setSelectedWalls, setSurfaces } from "../redux/surfaces/surfacesActions";
import { IFrameContext } from "../providers/IFrameContext";
import { SettingsService } from "../common/services/settings.service";
import * as _ from "lodash"
import { Event, EventTypes, VisualizerPageViewedEvent } from "../common/models/events/event.model";
import { EventsService } from "../common/services/events.service";
import { getFaIconsStyles, notBookmarkedIconStyles } from "../common/styles/styles";

const initialVisualizeData = {
    floor: [],
    ceiling: [],
    walls: []
}

export function SpaceVisualizer() {
    const theme = useTheme();
    const isMDandDown = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
    const {selectedProduct, products} = useSelector((state: FullState) => state.productsCatalog)
    const dispatch = useDispatch()
    const { height } = useWindowDimensions();
    const {visualizeData, isWallsSelected, isFloorSelected, isRugSelected} = useSelector((state: FullState) => state.surfaces)
    const isOnIframe = useContext(IFrameContext);

    const [fadeIn, setFadeIn] = useState(false);

    const vendorHasRugsProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "interactive_floor")))
    const vendorHasFloorProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "floor")))
    const vendorHasWallsProducts = () => (products.find( product => product.application_types.find(area => area.toLowerCase() === "walls")))

    useEffect(() => {
        if(products.length === 0)
            spaceCatalogService.initCatalog()
        setFadeIn(true);
        const selectedSurface = isFloorSelected ? "floor" : isWallsSelected ? "wall" : isRugSelected ? "rug" : ""
        EventsService.push(new Event(EventTypes.VisualizerPageViewedEvent, new VisualizerPageViewedEvent(selectedSpace, visualizeData, selectedSurface)))
    }, [])

    useEffect(() => {
        if(isOnIframe && selectedProduct && selectedSpace && !isRugSelected){
            const currentSpace = {...selectedSpace}
            let data = initialVisualizeData
            const isAFloor = selectedProduct.application_types.find(e => e === "floor") && vendorHasFloorProducts()
            const isAWall = selectedProduct.application_types.find(e => e === "walls") && vendorHasWallsProducts()
            if(isAFloor){
                dispatch(selectFloor())
                data.floor = [...data.floor, ...currentSpace.surfaceMarkers.floor.map(floor => ({
                    orientation: 0,
                    productId: selectedProduct.id,
                    surfaceKey: floor.id
                }))]
            } 
            else if(isAWall) {
                dispatch(selectWalls())
                dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls.map(e => e.id)))
                data.walls = [...data.walls, ...currentSpace.surfaceMarkers.walls.map(wall => ({
                    orientation: 0,
                    productId: selectedProduct.id,
                    surfaceKey: wall.id
                }))]
            }
            dispatch(setSurfaces(data, selectedSpace.id))
            runProcess(data)
        }
    }, [])

    useEffect(() => {
        if(isRugSelected && selectedSpace && products.length > 0){
            selectDefaultProductForRugs()
        }
    }, [isRugSelected, selectedSpace, products])

    useEffect(() => {
        if(!isOnIframe && products.length>0 && selectedSpace && !isRugSelected){
            const currentVisualizeData = {...initialVisualizeData}

            if(SettingsService.vendorHasFloor())
                currentVisualizeData.floor = selectLastSelectedProductsForFloors()
            if (SettingsService.vendorHasWalls())
                currentVisualizeData.walls = [...currentVisualizeData.walls, ...selectLastSelectedProductsForWalls()]

            //If no product is selected, select first one
            //If the space has floors, then :

            if(_.isEqual(currentVisualizeData, initialVisualizeData)){
                if(SettingsService.vendorHasFloor() && selectedSpace.surfaces.find(e => e === "floor"))
                    currentVisualizeData.floor = selectDefaultProductForFloors()
                else if(SettingsService.vendorHasWalls() && selectedSpace.surfaces.find(e => e === "walls"))
                    currentVisualizeData.walls = [...currentVisualizeData.walls, ...selectDefaultProductForWalls()]
            }

            setTimeout(() => {
                dispatch(setSurfaces(currentVisualizeData, selectedSpace.id))
                runProcess(currentVisualizeData)
            }, 500)

            if(isFloorSelected)
                dispatch(selectFloor())
            else if(isWallsSelected)
                dispatch(selectWalls())
             
        } 

    }, [products])

    useEffect(() => {
        if(SettingsService.vendorHasFloor() && vendorHasFloorProducts()){
            dispatch(selectFloor())
        } else if(SettingsService.vendorHasWalls() && vendorHasWallsProducts()) {
            dispatch(selectWalls())
            dispatch(selectWalls())
        } else if(SettingsService.vendorHasRugs() && vendorHasRugsProducts()) {
            dispatch(selectRug())
        }
    }, [])


    const selectLastSelectedProductsForWalls = () => {
        const wallsSelection = [];

        if(visualizeData.walls.length > 0 && selectedSpace.surfaceMarkers.walls){
            dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls.map(e => e.id)))
            selectedSpace.surfaceMarkers.walls.forEach(wall => {
                wallsSelection.push({
                    orientation: visualizeData.walls[0].orientation,
                    productId: visualizeData.walls[0].productId,
                    surfaceKey: wall.id                    
                })
            })
        } 

        return wallsSelection
    }

    const selectLastSelectedProductsForFloors = () => {
        const floorSelection = [];

        if(visualizeData.floor.length > 0 && selectedSpace.surfaceMarkers.floor){
            selectedSpace.surfaceMarkers.floor.forEach(floor => {
                floorSelection.push({
                    orientation: visualizeData.floor[0].orientation,
                    productId: visualizeData.floor[0].productId,
                    surfaceKey: floor.id                    
                })
            })
        }
        return floorSelection
    }

    const selectDefaultProductForFloors = () => {
        const floorSelection = [];
        const defaultProduct = products.filter(product => product.application_types.find(at => at === "floor"))[0]
        
        if(defaultProduct){
            selectedSpace.surfaceMarkers.floor.forEach(floor => {
                floorSelection.push({
                        orientation: 0,
                        productId: defaultProduct.id,
                        surfaceKey: floor.id                    
                    })
            })
            dispatch(setSelectedProduct(defaultProduct))
        }

        return floorSelection
    }

    const selectDefaultProductForRugs = () => {
        const rugSelection = [];
        const defaultProduct = products.filter(product => product.application_types.find(at => at === "interactive_floor"))[0]
        dispatch(setSelectedProduct(defaultProduct))
    }

    const selectDefaultProductForWalls = () => {
        const wallsSelection = [];
        const defaultProduct = products.filter(product => product.application_types.find(at => at === "walls"))[0]

        if(visualizeData.walls.length === 0){
            dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls.map(e => e.id)))
            selectedSpace.surfaceMarkers.walls.forEach(wall => {
                wallsSelection.push({
                    orientation: 0,
                    productId: defaultProduct.id,
                    surfaceKey: wall.id                    
                })
            })
        } 
        dispatch(setSelectedProduct(defaultProduct))
        return wallsSelection
    }
 
    const runProcess = (currentVisualizeData) => {
        if(selectedSpace && (currentVisualizeData.floor.length > 0 || currentVisualizeData.walls.length > 0 || currentVisualizeData.ceiling.length > 0))
            spaceCatalogService.processProduct(currentVisualizeData, selectedSpace)
        else if(selectedSpace && (currentVisualizeData.floor.length === 0 && currentVisualizeData.walls.length === 0 && currentVisualizeData.ceiling.length === 0)){
            dispatch(setSelectedProductResult(undefined))
            if(SettingsService.vendorHasFloor() && vendorHasFloorProducts())
                dispatch(selectFloor())
            else if(SettingsService.vendorHasWalls() && vendorHasWallsProducts()){
                dispatch(selectWalls())
                dispatch(setSelectedWalls(selectedSpace.surfaceMarkers.walls.map(e => e.id)))
            }
            else if(SettingsService.vendorHasRugs() && vendorHasRugsProducts())
                dispatch(selectRug())
        }

    }

    return (
        <div style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 2.5s ease' }}>
            <div style={{display: "flex", flexDirection: "column", overflow: 'hidden', backgroundColor: "#F4F6FC", height: height, width: "100vw"}}> 
                <Box sx={{
                    display: "flex",
                    flexDirection: isMDandDown ? "column-reverse" : "row",
                    margin: 0, 
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                    borderTop: "1px solid white",
                    flex: "auto",
                    background: "#f4f6fc"
                }}>
                    <SideBar />
                    { <SpacePlayground /> }
                    {/* { !isMDandDown && <RightSideBar /> } */}
                    


                </Box>
            </div>
        </div>
    )
}