import axios from 'axios';
import { v4 } from 'uuid';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const url = window.location.href;
  const vendorName = url.split("/")[4] && url.split("/")[4] !== "playground" ? url.split("/")[4]: "ardealy"
    // Do something before request is sent
    if(!config.url.includes("/vendors/auth/")){
      if(!config.headers["duuid"])
        config.headers["duuid"] = getDeviceToken()

      //@ts-ignore
      config.headers["Authorization"] = getAccessToken(vendorName.split("?")[0])
    }

    //@ts-ignore
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    return Promise.reject(error);
  });


export function getDeviceToken() {
    let machineId = localStorage.getItem('duuid');
    
    if (!machineId) {
        machineId = v4();
        localStorage.setItem('duuid', machineId);
    }
    return machineId;
}

export function getAccessToken(vendorName) {
  return `Bearer ${JSON.parse(localStorage.getItem(vendorName)).access_token}`
}

export default axios;