import { TransformComponent } from "react-zoom-pan-pinch"
import { SelectionIcons } from "../../Common/SelectionIcons"
import { useEffect, useState } from "react"
import { Global } from "@emotion/react"
import { useSelect } from "@material-tailwind/react"
import { useSelector } from "react-redux"
import { FullState } from "../../../../../../redux/rootReducer"
import CrossfadeImage from "react-crossfade-image";
import { Box, Typography } from "@mui/material"

export const InitialSpaceMobile = (props) => {
    const {isMobileDrawerOpen} = useSelector((state: FullState) => state.generals)
    const [imageSrc, setImageSrc] = useState(props.selectedSpace.uncroppedThumbnailPath);
    const [areIconsVisible, setAreIconsVisible] = useState(true)

    const isLandscape = () => {
        return (document.getElementById("space")?.clientHeight <= document.getElementById("space")?.clientWidth)
    }

    useEffect(() => {
        // Update the imageSrc state when props.selectedSpaceResult changes
        if (props.selectedSpaceResult) {
            setImageSrc(`data:image/jpeg;base64,${props.selectedSpaceResult}`);
        } else {
            setImageSrc(props.selectedSpace.uncroppedThumbnailPath);
        }
      }, [props.selectedSpaceResult, props.selectedSpace.uncroppedThumbnailPath]);
    

    return (
            <TransformComponent>
                <Global
                    styles={{
                        '.react-transform-wrapper': {
                            height: "calc(100vh - 90px)",
                            display: "flex",
                            marginTop: isMobileDrawerOpen ? "0px":"60px",
                            transition: "margin 1s ease",
                        },
                    }}
                    />
                    
                <Box onClick={() => setAreIconsVisible(!areIconsVisible)} display={"flex"} alignItems={"center"} position={"relative"} justifyContent={"center"}>
                    <Box sx={{
                        backgroundColor: "#FFFFFF90",
                        border: "1px solid #00000050",
                        borderRadius: 8,
                        padding: 1,
                        position: "absolute",
                        textAlign: "center",
                        top: "50px",
                        margin: "20px",
                        opacity: areIconsVisible ? "0" : "1",
                        visibility: areIconsVisible ? "hidden": "visible",
                        transition: "opacity 0.3s, visibility 0.3s", // Animation duration
                    }}>
                        <Typography variant="caption" style={{color: "#00000080"}}>Click on the screen to unhide markers</Typography>
                    </Box>
                    <CrossfadeImage src={imageSrc} 
                            style={{    width: "100%", 
                                        height:"calc(100vh - 90px)", 
                                        objectFit: "contain"
                            }}
                        />
                    <SelectionIcons {...props} 
                            setAreIconsVisible={(visibility) => setAreIconsVisible(visibility)} 
                            areIconsVisible={areIconsVisible}/>
                </Box>
                

            </TransformComponent>
    )
}