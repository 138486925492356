import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterProducts, filterProductsByName, setDisplayedProducts } from "../../../../redux/product/productActions";
import { MobileSearchBar } from "./mobile/_MobileSearchBar";
import { WebSearchBar } from "./web/_WebSearchBar";
import { FilterDialog } from "../Dialogs/FilterDialog";
import { SpaceCatalogService } from "../../services/space-catalog.service";
import { FullState } from "../../../../redux/rootReducer";
import { setDrawerHeight, toggleBookmarks, toggleCatalogShownAsList } from "../../../../redux/general/generalActions";
import useWindowDimensions from "../../../../common/hooks/GetWindowDimensions";
import { EventsService } from "../../../../common/services/events.service";

export const SearchBar = (props) => {
    const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false)
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false)
    const [searchInputText, setSearchInputText] = useState("")
    const theme = useTheme();
    const dispatch = useDispatch()
    const isMDandDown = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
    const { height, width } = useWindowDimensions();

    const bookmarks = useSelector((state: FullState) => state.bookmarks.bookmarks)
    const {isOnBookmarks} = useSelector((state: FullState) => state.generals)
    const { products } = useSelector((state: FullState) => state.productsCatalog)
    
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())

    const handleToggleBookmarks = (state) => {
        dispatch(toggleBookmarks(state))
        if(state)
          spaceCatalogService.showBookmarksOnly(bookmarks)
        else {
          if(!products || products.length === 0)
            spaceCatalogService.initCatalog()
          else
            dispatch(setDisplayedProducts(products))
        }
      }

    const handleOpenDialog = () => setIsFilterDialogOpen(!isFilterDialogOpen);
    const handleOpenSearchBar = () => {
        setIsSearchBarOpen(!isSearchBarOpen)
    }

    const handleSearchProducts = (text) => {
        setSearchInputText(text)
    }

    const setCatalogFilters = (filters) => {
        dispatch(filterProducts(filters))
    }

    const handleKeyDown = (key) => {
      if (key === 'Enter') {
        dispatch(filterProductsByName(searchInputText))
      }
    }

    return (
        <>
            {(isMDandDown && props.force !== "web") && <MobileSearchBar 
                                    isSearchBarOpen={isSearchBarOpen}
                                    searchInputText={searchInputText}
                                    handleSearchProducts={handleSearchProducts} 
                                    handleOpenSearchBar={handleOpenSearchBar} 
                                    handleToggleBookmarks={() => handleToggleBookmarks(!isOnBookmarks)}
                                    handleKeyDown={handleKeyDown}
                                    handleOpenDialog={handleOpenDialog}/> }
            {(!isMDandDown || props.force === "web") && <WebSearchBar 
                                    isSearchBarOpen={isSearchBarOpen}
                                    searchInputText={searchInputText}
                                    actAs={props.actAs}
                                    handleSearchProducts={handleSearchProducts} 
                                    handleOpenSearchBar={handleOpenSearchBar} 
                                    handleKeyDown={handleKeyDown}
                                    handleOpenDialog={handleOpenDialog}/> }
            <FilterDialog 
                        isOpen={isFilterDialogOpen} 
                        handleOpen={() => handleOpenDialog()} 
                        setCatalogFilters={setCatalogFilters} />
        </>
    );
}