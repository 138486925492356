import { useEffect, useState } from "react";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FullState } from "../../../redux/rootReducer";
import { ResultCompare } from "../components/Result/Compare/ResultCompare";
import { InitialSpace } from "../components/Result/Initial/InitialSpace";
import { ResultActions } from "../components/Result/ResultActions/ResultActions";
import { setSelectedProductRugResult } from "../../../redux/product/productActions";
import { SpaceCatalogService } from "../services/space-catalog.service";
import { RugSpace } from "../components/Result/Rug/RugSpace";
import { Global } from "@emotion/react";
import GlobalRug from "../components/Result/Rug/GlobalRug";
import { getFaIconsStyles } from "../../../common/styles/styles";
import { ResultCompareTwoProducts } from "../components/Result/CompareTwoProducts/ResultCompareTwoProducts";
import { Loader } from "../components/Loader/Loader";
import zIndex from "@mui/material/styles/zIndex";
import { RightPoppinBar } from "../components/Result/Common/RightPoppinBar";

const zoomComponentStyles = {
  overflow: 'hidden',
  background: '#eeeeee',
  objectFit: "cover",
  width: "100%",
  position: 'relative',
  backgroundColor: "white",
  borderRadius: 2,
  height: "calc(100vh - 90px)"
} as React.CSSProperties

export default function SpacePlayground() {
  const theme = useTheme();
  const isMDandDown = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const isMDandUp = useMediaQuery(theme.breakpoints.up('lg'));
  const {isOnCompareResult, isLoading} = useSelector((state: FullState) => state.generals)
  const selectedSpace = useSelector((state: FullState) => state.spacesPicker.selectedSpace)
  const selectedSpaceResult = useSelector((state: FullState) => state.productsCatalog.selectedProductResult)
  const [scaleWidth, setScaleWidth] = useState(1)
  const [scaleHeight, setScaleHeight] = useState(1)
  const [selectedSpaceImage, setSelectedSpaceImage] = useState(selectedSpace.uncroppedThumbnailPath)
  const [spaceHeight, setSpaceHeight] = useState(document.getElementsByClassName("CrossfadeImage")[0]?.clientHeight)
  const [spaceWidth, setSpaceWidth] = useState(document.getElementsByClassName("CrossfadeImage")[0]?.clientWidth)

  const [scale, setScale]= useState(undefined)
  const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())
  const {isRugSelected} = useSelector((state: FullState) => state.surfaces)

  const dispatch = useDispatch()

  const setScales = (e) => {
    if(selectedSpace){
      if(e?.state?.scale){
        setScale(e.state.scale)
      } else {
        const actualWidth = document.getElementsByClassName("CrossfadeImage")[0]?.clientWidth
        setScale(actualWidth / selectedSpace.width)
      }
    }
  }

  useEffect(() => {
    const getBase64FromUrl = async (imageUrl) => {
        try {
          // Fetch the image as a Blob
          const response = await fetch(imageUrl);
          const blob = await response.blob();
    
          // Convert the Blob to a base64 string
          const base64data: any = await convertBlobToBase64(blob);
          setSelectedSpaceImage(base64data)
        } catch (error) {
          console.error('Error converting image to base64:', error);
        }
    };
    getBase64FromUrl(selectedSpace.uncroppedThumbnailPath)
  }, [])

  useEffect(() => {
    setScales("")
    const processSpace = async () => {
      //const data = await spaceCatalogService.processRug(selectedSpace.id)
      //dispatch(setSelectedProductRugResult(data))
    }
    processSpace()
  }, [selectedSpace])

  useEffect(() => {
    setScales("")
  }, [document.getElementsByClassName("CrossfadeImage")[0]?.clientWidth])

  return (
    <Box display={"flex"} flexDirection={"column"} flex={2} sx={{backgroundColor: isMDandDown ? "#D9D9D9":""}} width={isMDandDown?"100vw":"60vw"}>
      <ResultActions />
      
      {selectedSpace && <Box id="playground" sx={{
                                  overflow: 'hidden',
                                  background: '#f4f6fc',
                                  objectFit: "cover",
                                  width: "100%",
                                  position: 'relative',
                                  backgroundColor: isMDandDown ? "#D9D9D9":"white",
                                  borderRadius: isMDandDown ? "none" : 2,
                                  height: isMDandDown? spaceHeight:"calc(100vh - 90px)",
                                  display: "flex",
                                  justifyContent: "center",
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0
                                }}>

        
        {!isOnCompareResult && !isRugSelected && <InitialSpace  selectedSpace={selectedSpace}
                                              selectedSpaceResult={selectedSpaceResult}
                                              scale={scale}
                                              setScales={setScales}
                                              scaleHeight={scaleHeight}
                                              scaleWidth={scaleWidth}
                                            />}

        {!isOnCompareResult && isRugSelected && <GlobalRug />}

        {isOnCompareResult && <ResultCompareTwoProducts  selectedSpace={selectedSpace}
                                              selectedSpaceResult={selectedSpaceResult}
                                              selectedSpaceImage={selectedSpaceImage}
                                              scale={scale}
                                              scaleHeight={scaleHeight}
                                              scaleWidth={scaleWidth}
                                              />}

        <Loader fullScreen={false}/>
      </Box>}

    </Box>
  );
}


const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};
