import { useDispatch, useSelector } from "react-redux";
import { Header } from "../modules/SpacePicker/components/Header/Header";
import { SpacesGallery } from "../modules/SpacePicker/layout/SpacesGallery";
import { useContext, useEffect, useState } from "react";
import { setSelectedWalls } from "../redux/surfaces/surfacesActions";
import { setSelectedProduct, setSelectedProductResult } from "../redux/product/productActions";
import { FullState } from "../redux/rootReducer";
import { setSelectedSpace } from "../redux/space/spaceActions";
import { Box, CircularProgress } from "@mui/material";
import { IFrameContext } from "../providers/IFrameContext";
import { Loader } from "../modules/Visualizer/components/Loader/Loader";

export function SpacePicker() {
    const dispatch = useDispatch()
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
    const isOnIframe = useContext(IFrameContext);
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        dispatch(setSelectedProductResult(undefined))
        if(!selectedSpace){
            dispatch(setSelectedProduct(undefined))
            dispatch(setSelectedWalls([]))
        } else {
            dispatch(setSelectedSpace(undefined))
        }
        setFadeIn(true);
    }, [])

    return (
        <>
        <div style={{ opacity: fadeIn ? 1 : 0, transition: 'opacity 1s ease' }}>
            <Header />
            <Box marginTop={isOnIframe ? 25 : 20}>
                <SpacesGallery />
            </Box>
        </div>
        <Box position={"fixed"} top={0} zIndex={99999999}>
            <Loader fullScreen />
        </Box>
        </>
    );
}