import axios from "../interceptors/api-client";
import { useDispatch } from "react-redux"
import Config from "../models/config.model";
import { pmsEndPoint } from '../../config/config.js'
import { getDeviceToken } from "../interceptors/api-client";
import { Event } from "../models/events/event.model";

export class EventsService {

    static async push(event: Event) {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: pmsEndPoint + '/events',
            data: event
        };

        await axios.request(config)
    }

}