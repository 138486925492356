import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { SpacePicker } from './pages/space-picker.page';
import { SpaceVisualizer } from './pages/space-visualizer.page';
import { ThemeProvider, createTheme } from '@mui/material';
import { defaultFonts, defaultPrimaryColor, defaultSecondaryColor, theme } from './theme';
import { SettingsService } from './common/services/settings.service';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import Config from './common/models/config.model';
import { useTranslation } from 'react-i18next';
import { setCompanyLogo } from './redux/general/generalActions';

import './all.scss'
import './App.scss'
import { initSurfaces } from './redux/surfaces/surfacesActions';
import { IframeProvider } from './providers/IFrameContext';
import { ARVisualizer } from './pages/ar-visualizer.page';
import { UploadSpace } from './pages/upload-space.page';
/** Import main fonts */
//import './common/fonts/fonts.js'

var assetsRegexPattern = new RegExp(`^${window.location.protocol}//${window.location.host}/public/[^/]+$`);

var vendorRegexPattern = new RegExp(`^${window.location.protocol}//${window.location.host}/visualizer/[^/]+$`);
var arRegexPattern = new RegExp(`^${window.location.protocol}//${window.location.host}/ar/.+$`);
var uploaderRegexPattern = new RegExp(`^${window.location.protocol}//${window.location.host}/space-uploader/.+$`);
var defaultRegexPattern = new RegExp(`^${window.location.protocol}//${window.location.host}/visualizer(?:/?)$`);

function App() {
    const { i18n } = useTranslation();
    const [settingsService] = useState<SettingsService>(new SettingsService())
    const [theme, setTheme] = useState(null)
    const [vendorName, setVendorName] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        // Prevent pinch-to-zoom globally
        const handleGlobalTouchMove = (event) => {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        };
    
        document.addEventListener('touchmove', handleGlobalTouchMove, { passive: false });
    
        // Re-enable zoom for a specific div by its ID
        const allowZoomOnDiv = (event) => {
          const zoomableDiv = document.getElementById('TransformWrapper'); // Replace 'zoomableDivId' with the actual ID
    
          if (zoomableDiv && zoomableDiv.contains(event.target)) {
            // Allow zoom on this div (do nothing)
            alert("HERE")
          } else {
            // Prevent zoom elsewhere
            if (event.touches.length > 1) {
              event.preventDefault();
            }
          }
        };
    
        document.addEventListener('touchmove', allowZoomOnDiv, { passive: false });
    
        // Clean up event listeners on component unmount
        return () => {
          document.removeEventListener('touchmove', handleGlobalTouchMove);
          document.removeEventListener('touchmove', allowZoomOnDiv);
        };
      }, []);

    const vendorTheme = useMemo(() =>
        {
            return createTheme(theme ?? {})
        }, [theme]);

    useEffect(() => {
        var url = window.location.href;
        if(assetsRegexPattern.test(url)){
            
        }
        else if(arRegexPattern.test(url) || vendorRegexPattern.test(url) || uploaderRegexPattern.test(url)) {
            const vendor = decodeURI(url.split("/")[4].split('?')[0])
            if(vendor === "ardealy"){
                window.location.replace(`${window.location.protocol}//${window.location.host}/visualizer`);
            }
            setVendorName(vendor)
        }
        else if (defaultRegexPattern.test(url)) {
            setVendorName("ardealy")
        } else if(!uploaderRegexPattern.test(url)) {
            const vendor = url.split("/")[4]
            if(vendor)
                window.location.replace(`${window.location.protocol}//${window.location.host}/visualizer/${vendor}`);
            else 
                window.location.replace(`${window.location.protocol}//${window.location.host}/visualizer`);
        } 

        const getConfig = async () => {
            const theme: Config = await settingsService.getCompanyTheme(vendorName)
            document.title = `${theme.company} - Space Visualizer | Powered By ARdealy`;
            dispatch(setCompanyLogo(theme.logo))
            i18n.changeLanguage(theme.language[0]);
            const font = theme && theme.language && theme.language[0] === "ar" ? "Readex Pro" : ( theme?.font ? theme.font : defaultFonts )
            const newTheme = {
                palette: {
                    primary: {
                        main: theme?.primaryColor ? theme.primaryColor : defaultPrimaryColor
                    },
                    secondary: {
                        main: theme?.secondaryColor ? theme.secondaryColor : defaultSecondaryColor
                    }
                },
                typography: {
                    fontFamily: font
                }
            }
            setTheme(newTheme)
        }

        if(vendorName){
            getConfig()
            dispatch(initSurfaces())
        }

    },[vendorName])

    return (
        <div dir={i18n?.language === "ar" ? "rtl": "ltr"}>
            <IframeProvider>
                {theme && <ThemeProvider theme={vendorTheme}>
                    <BrowserRouter>
                        <div className="App">
                            <Routes>
                                <Route path="/space-uploader/:vendorName" element={<UploadSpace />} />
                                <Route path="/visualizer" element={<SpacePicker />} />
                                <Route path="/visualizer/:vendorName" element={<SpacePicker />} />
                                <Route path="/visualizer/:vendorName/playground" element={<SpaceVisualizer />} />
                                <Route path="/visualizer/playground" element={<SpaceVisualizer />} />
                                <Route path="/ar/:vendorName/products/:productId" element={<ARVisualizer />} />
                            </Routes>
                        </div>
                    </BrowserRouter>
                </ThemeProvider>}
            </IframeProvider>
        </div>
    );
}
export default App;