import { Box, ImageListItem, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setSelectedSpace } from "../../../../redux/space/spaceActions";
import { DeleteForeverRounded } from "@mui/icons-material";
import { SpacePickerService } from "../../services/space-picker.service";
import { useState } from "react";
import { getFaIconsStyles } from "../../../../common/styles/styles";
import { useTranslation } from "react-i18next";
import { EventsService } from "../../../../common/services/events.service";
import { Event, EventTypes, SpaceSelectedEvent } from "../../../../common/models/events/event.model";

/**
* Renders space's image
*/
export function SpaceItem({ space, index, newHeight, deleteUploadedSpace = undefined }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [params] = useSearchParams();

 const goToVisualizer = () => {
    dispatch(setSelectedSpace(space))
    const productIdQuery = params.get("productId") ? `&productId=${params.get("productId")}` : ""
    const spaceIdQuery = `spaceId=${space.id}`
    EventsService.push(new Event(EventTypes.SpaceSelectedEvent, new SpaceSelectedEvent(space, index)))
    navigate({
      pathname: `${location.pathname}/playground`,
      //search: `?${spaceIdQuery}${productIdQuery}`,
    });
 }

 return (
  <>
      <Box
        position='relative'
        minHeight='100%'>
        <ImageListItem
          onClick={goToVisualizer}
          key={space.img}
          style={{ cursor: 'pointer', position: "relative" }}>
          <img
            src={`${space.thumbnailPath}`}
            srcSet={`${space.thumbnailPath}`}
            alt={space.title}
            loading="lazy"
            style={{ borderRadius: '8px', objectFit: 'cover', ...newHeight }}
          />
          { !space.uploadedByVisitor && <Typography paddingBlock={1}>{space.spaceType}</Typography>}

         </ImageListItem>

         { space.uploadedByVisitor && <i className="fa-light fa-trash-can fa-lg" onClick={() => deleteUploadedSpace(space)}
         style={{...getFaIconsStyles(), position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', zIndex: 70000, 
                  //@ts-ignore
                  "--fa-primary-color": "white", "--fa-primary-color": "black",
                  "--fa-primary-opacity": 1,
                  "--fa-secondary-opacity": 1,
                  backgroundColor: "white",
                  borderRadius: 50,
                  padding: 6
                  }}></i> }



      </Box>
      </>

 );
}