import { Box, Button, Card, CardContent, CardMedia, Chip, Divider, Tooltip, Typography } from "@mui/material";
import {Favorite} from '@mui/icons-material';
import * as _ from "lodash"
import "./WebProduct.scss"
import { Bookmark } from "../Common/Bookmark";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { EventsService } from "../../../../../common/services/events.service";
import { Event, EventTypes, ProductVisitVendorPageEvent } from "../../../../../common/models/events/event.model";
import { useSelector } from "react-redux";
import { FullState } from "../../../../../redux/rootReducer";
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer";

const MAX_CHARS_FOR_PRODUCT_NAME = 50

export const WebProduct = (props) => {
    const { t } = useTranslation();
    const [containerLength, setContainerLength] = useState(1)
    const [productSiblings, setProductSiblings] = useState(
        props.item.siblings.length > 0 ? 
        [...props.item.siblings, props.item].sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        : []
    )
    const {isFloorSelected, isWallsSelected, isRugSelected} = useSelector((state: FullState) => state.surfaces)
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
    const selectedProduct = useSelector((state: FullState) => state.productsCatalog.selectedProduct)

    useEffect(() => {
        if(document.getElementsByClassName("product-list-web")[0]?.clientWidth)
            setContainerLength(document.getElementsByClassName("product-list-web")[0]?.clientWidth)

    }, [document.getElementsByClassName("product-list-web")[0]?.clientWidth])


    const handleGoToProductPage = (event) => {
        event.stopPropagation()
        EventsService.push(new Event(EventTypes.ProductVisitVendorPageEvent, 
            new ProductVisitVendorPageEvent(
                props.item, selectedSpace, getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), props.key, true
            )
        ))
        window.open(props.item.link, '_blank');
    }

    const handleSiblingSelected = (event, oldProduct, sibling) => {
        event.stopPropagation()
        props.handleSiblingSelected(oldProduct, sibling)
    }

    return (
        <>{props.item && props.view == "web" && 
        
        <Box 
            className="product-web"
            
            sx={{
                    border: props.item.id === props.selectedProduct?.id ? 2: 2,
                    borderColor: props.item.id === props.selectedProduct?.id ? '#AFAFAF': '#D7DBE695',
                    maxWidth: props.isShownAsList ? "100%": `${containerLength/3}px`,
                    marginBottom: props.isShownAsList ? 1 : 0.5,
                    padding: props.isShownAsList ? 0.5: 0,
                    transition: "background-color 0.7s, border 0.7s", // Animation duration
                    "&:hover": {
                        backgroundColor: "#F9F9F9", // Adjusted color on hover
                        borderColor: '#AFAFAF'
                    },
            }}>
                <Box display={"flex"} onClick={() => props.handleProductSelect(props.item)} sx={{cursor: "pointer"}}>
                    <img
                            style={{ 
                                width: props.isShownAsList ? "100px": `${containerLength/3 - 14}px`, 
                                height: props.isShownAsList ? "100px":`${containerLength/3 - 14}px`, 
                                padding: props.isShownAsList ? 0 : "2px",
                                borderRadius: "8px",
                                margin: props.isShownAsList ? 0.5 : 0 }}
                            src={props.item.thumbnailPath}
                    />
                    
                    {props.showActions && <Bookmark {...props}/>}

                    {props.isShownAsList && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', position: "relative", width: "100%" }}>
                            <Box sx={{ flex: '1 0 auto', position: "relative", width: "100%", padding: 0, paddingTop: 1, paddingLeft: 1}}>
                                <Typography color="gray" className="mb-1" style={{fontSize: 12}}>
                                    {props.item.brand}
                                </Typography>
                                <Tooltip title={props.item.name} arrow>
                                    <Typography color="blue-gray" className="mb-2 font-bold" 
                                        style={{
                                            fontSize: 13, 
                                            paddingRight: 8,
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitLineClamp: 2, /* number of lines to show */
                                            lineClamp: 2,
                                            WebkitBoxOrient: "vertical"
                                        }} >
                                        {props.item.name.length > MAX_CHARS_FOR_PRODUCT_NAME ? props.item.name.slice(0, MAX_CHARS_FOR_PRODUCT_NAME) + '...' : props.item.name}
                                    </Typography>
                                </Tooltip>
                                {(!productSiblings || productSiblings.length === 0) && <Typography color="gray" style={{fontSize: 12}}>
                                    {props.item.width}{props.item.unit} x {props.item.length}{props.item.unit}
                                </Typography>}
                                {productSiblings?.length > 0 && 
                                <Typography color="black" sx={{fontSize: 12}}>
                                    ({productSiblings?.length} variants)
                                </Typography>}
                                
                            </Box>
                            
                        </Box>
                    )}
                </Box>
                {selectedProduct?.id === props.item.id && props.isShownAsList && productSiblings?.length > 0 &&
                <Box>
                    <Typography color="black" sx={{fontSize: 12, marginTop: 1}}>
                        <b>Color:</b> {props.item.color} - <b>Size:</b> {props.item.width}{props.item.unit} x {props.item.length}{props.item.unit}
                    </Typography>
                    <Box marginTop={0} >
                        <Box display={"flex"} flexWrap={"wrap"} marginTop={0}>
                            {productSiblings.map((sibling, index) => (
                                <CardMedia
                                    key={index}
                                    component="img"
                                    sx={{ 
                                        width: "30px", 
                                        height: "30px", 
                                        padding: "2px",
                                        borderRadius: 1,
                                        cursor: "pointer",
                                        border: selectedProduct.id === sibling.id ? "1px solid #AFAFAF": "1px solid transparent",
                                        marginRight: 0.5 }}
                                    image={sibling.thumbnailPath}
                                    onClick={(e) => handleSiblingSelected(e, props.item, sibling)}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>}
                {props.isShownAsList && props.showActions && <Box display={"flex"} justifyContent={"flex-end"}
                marginTop={selectedProduct?.id === props.item.id && props.isShownAsList && productSiblings?.length > 0 ? 0 : -2}
                >
                    <Typography variant="caption" style={{fontSize: 12, color: "#7B8297"}} onClick={handleGoToProductPage}>
                        {t('VISUALIZER.BUTTONS.GO_TO_PRODUCT_PAGE')}
                        <i className="fas fa-link" style={{ marginLeft: 5}}/>
                    </Typography>
                </Box>}
        </Box>
            
            
        }</>
    )
}