import { Checkbox, Accordion, Typography, AccordionSummary, AccordionDetails } from "@mui/material"
import {ArrowDownward} from '@mui/icons-material';

export const FilterCategory = (props) => {

    const handleCheckboxChange = (checked, element) => {
        if(checked){
            props.addToSelectedFilters(element, props.filterName)
        } else {
            props.removeFromSelectedFilters(element, props.filterName)
        }
    }

    return (<>
        <Accordion>
            <AccordionSummary
                expandIcon={<ArrowDownward />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>{props.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.filterElements && props.filterElements.map((element) => (
                        <tr className="">
                            <td style={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox color="primary" onChange={(event) => handleCheckboxChange(event.target.checked, element)} checked={props.selectedFilters[props.filterName]?.findIndex(e => e === element) >= 0}/> <Typography>{element}</Typography>
                            </td>
                        </tr>
                    ))}
            </AccordionDetails>
        </Accordion>
    </>)
}
