import { Box, Tooltip, Typography } from "@mui/material"
import { getFaIconsStyles } from "../../../../../common/styles/styles"
import { useDispatch, useSelector } from "react-redux"
import { FullState } from "../../../../../redux/rootReducer"
import { SettingsService } from "../../../../../common/services/settings.service"
import { VisualizeModel } from "../../../models/visualize.model"
import { Event, EventTypes, ProductCompareClickedEvent, ProductCompareClosedClickedEvent, ProductDeletedEvent, ProductRotatedEvent } from "../../../../../common/models/events/event.model"
import { getActiveSurface } from "../../../../../redux/surfaces/surfacesReducer"
import { EventsService } from "../../../../../common/services/events.service"
import { selectFloor, selectWalls, setSurfaces } from "../../../../../redux/surfaces/surfacesActions"
import { useState } from "react"
import { SpaceCatalogService } from "../../../services/space-catalog.service"
import { setSelectedProduct } from "../../../../../redux/product/productActions"
import { setCloseCompare, setResultCompare } from "../../../../../redux/general/generalActions"
import { useTranslation } from "react-i18next"

export const RightPoppinBar = (props) => {
    const { t } = useTranslation();
    const {isOnCompareResult, isLoading} = useSelector((state: FullState) => state.generals)
    const {selectedWallsIds, isFloorSelected, isWallsSelected, isRugSelected, visualizeData } = useSelector((state: FullState) => state.surfaces)
    const {selectedProductResult, selectedProduct} = useSelector((state: FullState) => state.productsCatalog)
    const {selectedSpace} = useSelector((state: FullState) => state.spacesPicker)
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())

    const [isRotateSelected, setIsRotateSelected] = useState(false)

    const dispatch = useDispatch()

    const handleRotateSurface = (degree) => {
        let data = visualizeData
        if(isFloorSelected){
          data = rotateProductForSurface(data, degree, 0, "floor")
          dispatch(setSurfaces(data, selectedSpace.id))
          runProcess(data)
        } else if(isWallsSelected) {
            selectedWallsIds.forEach((wallId, index) => {
                data = rotateProductForSurface(data, degree, wallId, "walls")
                if(index === selectedWallsIds.length -1 ){
                    dispatch(setSurfaces(data, selectedSpace.id))
                    runProcess(data)
                }
            });
        }
    }

    const rotateProductForSurface = (data: VisualizeModel, degree, surfaceId, surfaceType) => {
        const newSurfaces = data[surfaceType].map(surface => {
            if(surface.surfaceKey === surfaceId){
                const newOrientation = surface.orientation + degree === 360 ? 0 : surface.orientation + degree
                EventsService.push(new Event(EventTypes.ProductRotatedEvent, new ProductRotatedEvent(selectedProduct, selectedSpace, getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}), newOrientation)))
                return {
                    ...surface,
                    orientation: newOrientation
                }
            } else {
                return surface
            }
        })

        return {
            ...data,
            [surfaceType]: newSurfaces
        }
    }

    const handleRemoveProduct = () => {
        //searchParams.set('productId', product.id)
        dispatch(setSelectedProduct(undefined))
        let data = visualizeData
    
        if(isWallsSelected){
          selectedWallsIds.forEach(wall => {
            data = removeProductForSurface(data, wall, "walls")
          })
          dispatch(selectWalls())
        }
          
        if(isFloorSelected){
            data = removeProductForSurface(data, 0, "floor")
            dispatch(selectFloor())
        }
        dispatch(setSurfaces(data, selectedSpace.id))
        runProcess(data)
        EventsService.push(new Event(EventTypes.ProductDeletedEvent, new ProductDeletedEvent(selectedProduct, selectedSpace, getActiveSurface({isFloorSelected, isWallsSelected, isRugSelected}))))
    }

    const removeProductForSurface = (data: VisualizeModel, surfaceId, surfaceType) => {
        const existingSurface = data[surfaceType].find(surface => surfaceId === surface.surfaceKey)
        let surfaces = data[surfaceType]
        if(existingSurface)
            surfaces = data[surfaceType].filter(surface => surface !== existingSurface)
        
        return {
            ...data,
            [surfaceType]: surfaces.filter(surface => surface.surfaceKey !== surfaceId)
        }
      }


    const handleCompareClick = () => {
        if(!isOnCompareResult){
            EventsService.push(new Event(EventTypes.ProductCompareClickedEvent, new ProductCompareClickedEvent(visualizeData)))
            dispatch(setResultCompare(!isOnCompareResult))
            dispatch(setCloseCompare(false))
        }
        else {
            EventsService.push(new Event(EventTypes.ProductCompareClosedClickedEvent, new ProductCompareClosedClickedEvent()))
            dispatch(setCloseCompare(true))
        }
    }

    const runProcess = (data) => {
        spaceCatalogService.processProduct(data, selectedSpace)
    }

    return <Box display="flex" flexDirection={"row-reverse"} position="absolute" 
                sx={{
                    right: 0, zIndex: 100000, top: '45%',
                    opacity: !props.areIconsVisible ? "0" : "1",
                    visibility: !props.areIconsVisible ? "hidden": "visible",
                    transition: "opacity 0.3s, visibility 0.3s",
                }}>
            <Box display="flex" flexDirection={"column"} 
                    sx={{backgroundColor: 'white', borderRadius: 2, borderTopRightRadius: 0, borderBottomRightRadius: 0, boxShadow: '1px 0 5px -1px #88888850', border: "1px solid #00000010"}}
                >
                <Tooltip title={t("VISUALIZER.BUTTONS.REMOVE_PRODUCT")} arrow placement="left">
                    {!isRugSelected && <Box display="flex" flex={2} justifyContent={"center"} alignItems="center">
                        <Box 
                            sx={{ cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading ? 'pointer': "not-allowed", padding: 1 }}
                            onClick={() => (((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading) && handleRemoveProduct() }>
                            <i className="fad fa-trash-can fa-xl" style={{...getFaIconsStyles(), marginLeft: 0}}></i>
                        </Box>
                    </Box>}
                </Tooltip>

                <Tooltip title={t("VISUALIZER.BUTTONS.ROTATE_SURFACE")} arrow placement="left">
                    {!isRugSelected && <Box display="flex" flex={2} justifyContent={"center"} alignItems="center">
                        <Box onMouseEnter={() => {setIsRotateSelected(true)}} onMouseLeave={() => {setIsRotateSelected(false)}}
                                // onClick={() => (((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading) && handleRotateSurface()}
                                // onClick={() => setIsRotateSelected(!isRotateSelected)}
                                sx={{ cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading ? 'pointer': "not-allowed", padding: 1, position: "relative" }} 
                            >
                            <i className="fad fa-rotate-right fa-xl" style={{...getFaIconsStyles(), marginLeft: 0}}></i>
                        </Box>
                    </Box>}
                </Tooltip>

                <Tooltip title={t("VISUALIZER.BUTTONS.COMPARE")} arrow placement="left">
                    <Box display="flex" flex={2} justifyContent={"center"} alignItems="center">
                        <Box 
                            sx={{ cursor: (selectedProductResult || isRugSelected) && !isLoading ? 'pointer': "not-allowed", padding: 1 }}
                            onClick={() => ((selectedProductResult || isRugSelected) && !isLoading) && handleCompareClick()}>
                            {!isOnCompareResult && <i className="fad fa-images fa-xl" style={getFaIconsStyles()} ></i>}
                            {isOnCompareResult && <i className="fad fa-close fa-xl" style={getFaIconsStyles()} ></i>}
                        </Box>
                    </Box>
                </Tooltip>

            </Box>
            {isRotateSelected && <Box
                onMouseEnter={() => {setIsRotateSelected(true)}}
                onMouseLeave={() => {setIsRotateSelected(false)}}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                marginRight={-0.2}
                paddingRight={-0.2}
                >
                {!isRugSelected && <Box display="flex" justifyContent={"center"} alignItems="center">
                    <Box    
                            onClick={() => (((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading) && handleRotateSurface(90)}
                            sx={{   backgroundColor: 'white', borderRadius: 2, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: isWallsSelected ? "8px":0,
                                    cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading ? 'pointer': "not-allowed", padding: 1, position: "relative"
                                }} 
                        >
                        <i className="fad fa-rotate-right fa-xl" style={{...getFaIconsStyles()}}></i>
                        <Typography variant="caption" fontWeight={"bold"} marginLeft={1} sx={{
                            color: "white", right: 2, position: "absolute", background: SettingsService.settings?.primaryColor, borderRadius: "50%", fontSize: "7pt", padding: 0.1, bottom: 2
                        }}>90°</Typography>
                    </Box>
                </Box>}
                {!isWallsSelected && !isRugSelected && <Box display="flex" justifyContent={"center"} alignItems="center">
                    <Box 
                            onClick={() => (((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading) && handleRotateSurface(45)}
                            sx={{   backgroundColor: 'white', borderRadius: 2, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0,
                                    cursor: ((selectedProductResult && selectedProduct) || isRugSelected) && !isLoading ? 'pointer': "not-allowed", padding: 1, position: "relative" 
                                }} 
                        >
                        <i className="fad fa-rotate-right fa-xl" style={{...getFaIconsStyles()}}></i>
                        <Typography variant="caption" fontWeight={"bold"} marginLeft={1} sx={{
                            color: "white", right: 2, position: "absolute", background: SettingsService.settings?.primaryColor, borderRadius: "50%", fontSize: "7pt", padding: 0.1, bottom: 2
                            }}>45°</Typography>
                    </Box>
                </Box>}
            </Box>}
        </Box>
}
