export const TOGGLE_LOADING = "TOGGLE_LOADING"
export const TOGGLE_BOOKMARKS = "TOGGLE_BOOKMARKS"
export const HIDE_CATALOG = "HIDE_CATALOG"
export const SHOW_CATALOG = "SHOW_CATALOG"
export const SET_RESULT_COMPARE = "SET_RESULT_COMPARE"
export const SET_CLOSE_COMPARE = "SET_CLOSE_COMPARE"

export const TOGGLE_CATALOG_SHOWN_AS_LIST = "TOGGLE_CATALOG_SHOWN_AS_LIST"

export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO"

export const TOGGLE_MOBILE_DRAWER = "TOGGLE_MOBILE_DRAWER"
export const SET_DRAWER_HEIGHT = "SET_DRAWER_HEIGHT"