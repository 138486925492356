import { useEffect, useState } from "react"
import { FilterCategory } from "./_FilterCategory"
import { SpaceCatalogService } from "../../services/space-catalog.service"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useTheme } from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery';
import { Close } from "@mui/icons-material";

export const FilterDialog = (props) => {
    const [filters, setFilters] = useState<any>()
    const [selectedFilters, setSelectedFilters] = useState<any>({})
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [spaceCatalogService] = useState<SpaceCatalogService>(new SpaceCatalogService())

    useEffect(() => {
        setFilters(spaceCatalogService.getProductsFilters())
    },[])

    const addToSelectedFilters = (item, filterName) => {
        setSelectedFilters({
            ...selectedFilters,
            [filterName]: selectedFilters[filterName] ? [...selectedFilters[filterName], item]: [item],
        })
    }

    const removeFromSelectedFilters = (item, filterName) => {
        setSelectedFilters({
            ...selectedFilters,
            [filterName]: selectedFilters[filterName].filter(e => e !== item),
        })
    }

    const resetFilters = () => {
        setSelectedFilters({})
        props.setCatalogFilters(null)
        props.handleOpen()
    }

    const confirmFilters = () => {
        props.setCatalogFilters(selectedFilters)
        props.handleOpen()
    }

    return (
        <Dialog open={props.isOpen} fullScreen={fullScreen} maxWidth="lg" fullWidth>
            <DialogTitle >Filters</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => props.handleOpen()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <Close />
            </IconButton>
            <DialogContent className="overflow-y-scroll" style={{maxHeight: "80vh"}}>
                {filters && Object.keys(filters) && Object.keys(filters).map((filterName, index) => (
                    <FilterCategory name={capitalizeFirstLetter(filterName)} 
                                    key={index} 
                                    filterName={filterName} 
                                    filterElements={filters[filterName]} 
                                    selectedFilters={selectedFilters}
                                    index={index} 
                                    removeFromSelectedFilters={removeFromSelectedFilters}
                                    addToSelectedFilters={addToSelectedFilters}/>)
                )}
            </DialogContent>
            <DialogActions >
                <Button  variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => resetFilters()}>
                    <span>Reset filters</span>
                </Button>
                <Button  variant="contained" color="primary" onClick={() => confirmFilters()}>
                    <span>Confirm</span>
                </Button>
            </DialogActions>
        </Dialog>
    )
    

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}