import { Box, Typography } from "@mui/material"

import LoadingImage from "../../../../assets/loader.gif"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { FullState } from "../../../../redux/rootReducer"

export const Loader = ({fullScreen}) => {
    const {isLoading} = useSelector((state: FullState) => state.generals)
    const [isLoaderVisible, setIsLoaderVisible] = useState<Boolean>(isLoading)
    const [loaderText, setLoaderText] = useState<string>("Loading...")

    useEffect(() => {
        setIsLoaderVisible(isLoading)
    }, [isLoading])

    return <>{isLoaderVisible && <Box position={fullScreen ? "fixed": "absolute"} zIndex={1000000} 
                            display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}
                            sx={{userSelect: "none"}}
                            width={"100%"} height={"100%"} bgcolor={fullScreen ? "#00000090":"transparent"} top={0} left={0}>
                                <Box width={"100px"} height={"100px"} bgcolor={!fullScreen ? "#00000090":"transparent"} display="flex" justifyContent={"center"} alignItems={"center"} borderRadius={2}>
                                    <img src={LoadingImage} width={"75px"}/>
                                </Box>
                </Box>}
    </>
}